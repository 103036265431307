<template>
  <div class="d-flex flex-column align-items-center col-12">
    <div class="navbar-container">
      <div v-if="windowWidth >= 500">
        <DesktopNavBar/>
      </div>
      <div v-if="windowWidth < 500">
        <MobileNavBar/>
      </div>
    </div>
    <div class="d-flex flex-column align-items-center dashboard-container col-12 mb-3">
      <button @click="logoutClicked()" v-if="windowWidth >= 500" class="logout-button">Logout</button>
      <div class="header-text">Our Penrose Team!</div>
      <div class="d-flex flex-column align-items-center col-xl-4 col-11 mt-3" v-for="(staffMember) in staffDetails" :key="staffMember.id">
        <div class="d-flex flex-column col-12">
          <div class="staff-name mt-2">{{ staffMember.FullName }}</div>
          <div class="mt-1">{{ staffMember.Email }}</div>
          <div class="d-flex flex-row number-birthday-text pb-1">
            <div>{{ this.splitStringEveryThreeCharacters('0' + staffMember.Msisdn.slice(2)) }}</div>
            <div class="divider-line">|</div>
            <div>{{ this.formatDOB(staffMember.DateOfBirth) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import MobileNavBar from "@/views/components/mobile/MobileNavBar";
import DesktopNavBar from "@/views/components/desktop/DesktopNavBar";
import DashboardService from "@/services/dashboardService";
import moment from "moment";
import {mapMutations} from "vuex";

const windowWidth = ref(window.innerWidth)
const handleResize = () => {
  windowWidth.value = window.innerWidth
}

export default {
  name: "OurTeam",
  components: {MobileNavBar, DesktopNavBar},
  data() {
    return {
      windowWidth: windowWidth,
      staffDetails: [],
    }
  },
  methods: {
    ...mapMutations(['setUserData', 'setIsLoading']),
    logoutClicked() {
      this.setUserData(undefined);
      this.routeToPage('/login')
    },
    async getStaffDetails() {
      const response = await DashboardService.getStaffDetails();
      this.staffDetails = this.shuffleArray(response.data);
    },
    shuffleArray(array) {
      const newArr = array.slice()
      for (let i = newArr.length - 1; i > 0; i--) {
        const rand = Math.floor(Math.random() * (i + 1));
        [newArr[i], newArr[rand]] = [newArr[rand], newArr[i]];
      }
      return newArr
    },
    formatDOB(birthDate) {
      return moment(birthDate).format("D MMMM")
    },
    splitStringEveryThreeCharacters(inputString) {
      let regex = /(\S{1,3})(\S{1,3})/;

      let resultString = inputString.replace(regex, function (match, group1, group2, group3) {
        return (group1 ? group1 + ' ' : '') + (group2 ? group2 + ' ' : '') + (group3 ? group3 + ' ' : '');
      });

      return resultString.trim();
    },
  },
  mounted() {
    window.addEventListener('resize', handleResize)
  },
  unmounted() {
    window.removeEventListener('resize', handleResize)
  },
  async beforeMount() {
    this.setIsLoading(true);
    await this.getStaffDetails();
    this.setIsLoading(false);
  }
}
</script>

<style scoped>
.dashboard-container {
  position: relative;
  top: 0;
  padding-top: 15px;
  height: 92vh;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100vw;
}

.logout-button {
  position: absolute;
  background-color: #EE5C36;
  color: #FFFFFF;
  font-size: 13px;
  right: 10px;
  top: 10px;
  border: 0;
  height: 36px;
  width: 100px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.dashboard-container::-webkit-scrollbar {
  display: none;
}

.header-text {
  font-size: 60px;
  color: #EE5C36;
  line-height: 1.1;
  margin-left: 30px;
}

.staff-name, .divider-line {
  color: #EE5C36;
}

.divider-line {
  margin-left: 5px;
  margin-right: 5px;
}

.number-birthday-text {
  border-bottom: 1.6px solid #EE5C36;;
}

@media screen and (max-width: 500px ) {
  .header-text {
    font-size: 40px;
  }
}
</style>
